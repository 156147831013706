<template>
    <v-card id="top" class="custom-border border pa-5">
        <v-row no-gutters v-if="!loading">
          <v-col cols="12" lg="7">
            <img 
                alt="course image"
                :src="course.image ? course.image.url : require('../../../assets/images/course-photo.png')" 
                v-on:error="course.image = null"
                class="rounded-lg"
                height="300"
                width="100%"
                />
            <OtherCourseDetail :course="course"/>
          </v-col>
          <v-spacer />
          <v-col cols="12" lg="4">
            <v-card-text>
                <OtherCourseTimeline :course="course"/>
            </v-card-text>
          </v-col>
          <v-col cols="12">
            <v-card-text v-if="other_courses.length > 0" class="mt-5">
                <div class="poppins fw600 f18 secondary-2--text">Browse for more courses</div>
                <v-slide-group
                    show-arrows
                >
                    <v-slide-item v-for="course in other_courses" :key="course.id">
                        <GalleryCard class="ma-3" :course="course" />
                    </v-slide-item>
                </v-slide-group>
            </v-card-text>
          </v-col>
        </v-row>
        
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
            rounded
            height="8"
            class="ma-auto"
            :style="'width: 180px; margin-top: 25vh'"
        ></v-progress-linear>

    </v-card>
</template>

<script>

import { mapActions, mapState } from "vuex"
import OtherCourseDetail from '../../../components/user/course/tocart/OtherCourseDetail.vue';
import OtherCourseTimeline from '../../../components/user/course/tocart/OtherCourseTimeline.vue';
import GalleryCard from "../../../components/user/course/GalleryCard.vue";

export default {
    components: {
    OtherCourseTimeline,
    OtherCourseDetail,
    GalleryCard
},
    data: () => ({
        loading: false
    }),
    mounted() {
        this.$vuetify.goTo("#top")
        this.loading = true
        this.otherCoursesAction()
        this.getOtherCourseAction(this.$route.params.uuid).then(() => {
            this.loading = false
        })
    }, 
    computed: {
        ...mapState({
            course: (state) => state.course,
            other_courses: (state) => state.other_courses,
        }),
        ...mapState('usr', {
            cart: (state) => state.cart
        })
    },
    methods: {
        ...mapActions(['getOtherCourseAction', 'otherCoursesAction']),
    },
    watch: {
        '$route.params.uuid'( val ){
            this.$vuetify.goTo('#top')
            this.loading = true
            this.getOtherCourseAction(this.$route.params.uuid).then(() => {
                this.loading = false
            })
        }
    }
}
</script>