<template>
  <section>
    <div class="poppins fw600 f16 mb-5">
      Course Outline
    </div>

    <div v-for="(item) in course.modules" :key="item.id" class="mb-5 poppins">
      <section class="description_div primary--text fw600 f14">
        {{item.title ? item.title : 'Untitled' }} 
      </section>
      <!-- <section v-if="item.description" class="description_div f13 mt-1 secondary-2--text" v-html="item.description"></section> -->
      <section v-if="item.valid_until !== 'anytime'" class="f13">
        SCHEDULE: {{ $dateFormat.mmDDyy(item.valid_until) }}
      </section>
    </div>
  </section>
    <!--<section ref="materialTimeline" :style="{'--bgColor': getPrimary}">
      <v-expansion-panels class="" accordion multiple  v-model="expand_material">
        <v-expansion-panel
          v-for="item in course.modules"
          :key="item.id"
          multiple
        >
          <v-expansion-panel-header color="secondary-5"
          class="m-0 p-0 mat_expansion_panel ">
            <template v-slot:default="{  }">
              <v-container class="m-0 p-0 d-flex flex-column description_div">
                <section class="d-flex flex-row align-center description_div justify-space-between roboto f14 fw500 secondary-1--text ">
                  <section class="description_div">
                    <b>{{item.title ? item.title : 'Untitled' }}</b> 
                  </section>
                </section>
                <section v-if="item.description" class="description_div f12 mt-1 secondary-2--text" v-html="item.description"></section>
              </v-container>
              </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="expansion-timeline description_div">
            <v-container v-if="!item.topics" class="roboto secondary-2--text f12 fw600 pl-10">
              No modules available
            </v-container>
            <div v-else class="timeline active">
              <div class="timeline-container topic" 
                v-for="(data, j) in item.topics" 
                :key="j"
              >
                <v-list-item dense class="d-flex align-center timeline-content">
                  <v-icon small color="primary" v-if="data.learning_material != null">
                    {{ data.learning_material.file_type == 'youtube'
                      ? 'mdi-youtube'
                      : data.learning_material.file_type == 'pdf'
                      ? 'mdi-file-pdf-box'
                      : data.learning_material.file_type == 'mp4' 
                      ? 'mdi-file-video-outline'
                      : 'mdi-folder-question-outline'}}
                      
                  </v-icon>
                  <v-icon small color="primary" v-else>mdi-eye-off-outline</v-icon>
                  <v-list-item-title class="roboto secondary-1--text ml-2"
                    :class="data.is=='topic'? 'f13 fw600':'f12 fw500'">
                    {{data.title}}
                  </v-list-item-title>
                  
                </v-list-item>
                <section v-if="data.topic_has_assessments" >
                  <div
                      v-for="assessments in data.topic_has_assessments"
                      :key="assessments.id"
                      class="timeline-container assessments">
                      <v-list-item
                          dense 
                          link
                          class="d-flex align-center pl-8 timeline-content">
                          <v-icon small color="primary">
                          mdi-note-edit-outline
                          </v-icon>
                          <v-list-item-title  class="roboto secondary-1--text ml-2 f12 fw500">
                          {{assessments.title}}
                          </v-list-item-title>
                      </v-list-item>
                      </div>
                </section>

                <div class="timeline-container subtopic assessment " 
                    v-for="(_data, j) in data.sub_topics" 
                    :key="j"
                  >
                    <v-list-item dense class="d-flex align-center timeline-content pl-8">
                      <v-icon small color="primary" v-if="_data.learning_material != null">
                        {{ _data.learning_material.file_type == 'youtube'
                          ? 'mdi-youtube'
                          : _data.learning_material.file_type == 'pdf'
                          ? 'mdi-file-pdf-box'
                          : _data.learning_material.file_type == 'mp4' 
                          ? 'mdi-file-video-outline'
                          : 'mdi-folder-question-outline'}}
                          
                      </v-icon>
                      <v-icon small color="primary" v-else>mdi-eye-off-outline</v-icon>
                      <v-list-item-title class="roboto secondary-1--text ml-2"
                        :class="_data.is=='topic'? 'f13 fw600':'f12 fw500'">
                        {{_data.title}}
                      </v-list-item-title>
                    </v-list-item>
      
                        <section v-if="_data.sub_topic_has_assessments" >
                            <div 
                                v-for="assessments in _data.sub_topic_has_assessments"
                                :key="assessments.id"
                                class="timeline-container assessments">
                                    <v-list-item
                                        dense 
                                        link
                                        class="d-flex align-center pl-13 timeline-content">
                                        <v-icon small color="primary">
                                            mdi-note-edit-outline
                                        </v-icon>
                                        <v-list-item-title  class="roboto secondary-1--text ml-2 f12 fw500">
                                            {{assessments.title}}
                                        </v-list-item-title>
                                    </v-list-item>
                                </div>
                        </section>
                    </div>
                
                </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </section> -->
  </template>
  
<style>
    .v-expansion-panel-header.mat_expansion_panel {
        align-items: center !important;
        white-space: normal !important;
        word-wrap: break-word !important;
    }
    .description_div {
        white-space: normal !important;
        word-wrap: break-word !important;
    }
    .expansion-timeline .v-expansion-panel-content__wrap {
        padding-bottom: 0 !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .topic.timeline-container::after {
        background-color: var(--bgColor) !important;
        border:  2px solid var(--bgColor) !important;
    }
    .subtopic.timeline-container::after {
        background-color: var(--bgColor) !important;
        border:  2px solid var(--bgColor) !important;
    }
    .assessments.timeline-container::after {
        background-color: var(--bgColor) !important;
        border:  2px solid var(--bgColor) !important;
    }

</style>
  
<style scoped>
p {
margin-bottom: 5px !important;
}
</style>
  
<script>
//   import { mapActions, mapState } from 'vuex';
export default {
    name: 'course-timeline',
    props: ['course'],
    data: () => ({
        expand_material: null,
    }),
    computed: {

        getPrimary(){
        return this.$vuetify.theme.currentTheme.primary
        },
    },


    mounted() {
    },

    methods: {
        
    }

}
</script>